@import url('https://fonts.googleapis.com/css2?family=Alegreya&family=Lora:wght@600&family=Oswald:wght@300;400&display=swap');

.testimonial__content h2 {
    font-weight: 600;
  }
  
  .section-background {
    background: url('../../assests/images/bg11.jpg') center center/cover no-repeat; 
    padding: 20px; 
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tst1{
  text-align: center;
  font-family: Montserrat, sans-serif;
  position:relative;
  color:#171721;
  font-size:2rem;
  margin-top: 0px;
  font-style: normal;
  font-weight:bolder;
  margin-bottom: 40px;
  
  }
  .tst1::after{ 
    content: '';
    position: absolute;
    width: 150px;
    height: 3px;
    background-color:gold;
    border-radius: 50px;
    transform: translateX(-50%);
    left: 50%;
    bottom:-8px;
  }


 
  
  .video-row {
    
    display:inline-block;
     margin-left: 50px;
    
     
   
    
    
  }
  
  .video-container {
    border-radius: 30px;
    text-align: center;
     margin-right: 5px; 
       
     
  }
  .video-container img {
    border-radius: 10px;
    /* border: 2px solid #000;  */
    -webkit-box-shadow:0px 0px 21px 1px rgba(0,0,0,0.51);
  -moz-box-shadow: 0px 0px 21px 1px rgba(0,0,0,0.51);
  box-shadow: 0px 0px 21px 1px rgba(0,0,0,0.51);
    
  }
  
  
  .label1{
    font-style: normal;
    font-family:sans-serif;
    
  
  }

  .structure1 {
    display: grid;
    grid-template-columns: 1fr;

    
    @media screen and (min-width: 600px) {
        grid-template-columns: 1fr 1fr; 
    }

   
    @media screen and (min-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}