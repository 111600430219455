.footer {
  background:black;
  border-top-right-radius: 50%;
  border-top-left-radius: 80px;
  margin-top: 20px;
}

.follows span a {
  text-decoration: none;
}

.follows span {
  margin-right: 15px;
}

.link__list .link__item {
  background: transparent;
}

.link__list a {
  text-decoration: none;
  color:darkgoldenrod;
  font-weight: 500;
}
.txt1{
  font-weight: 700;
  font-size: 20px;
  color:azure;
  margin-bottom: 30px;
  font-family:'';
  


}
.ex1{

  
  color:whitesmoke;
  font-weight: 800;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size:larger
}

.ex2{

  color:white;
  font-style: normal;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-size: .8rem;
  color:rgb(255, 255, 249);
}

.ex3{
  border: 0;
  background:transparent;
  
  /* position: relative; */
  text-decoration: none;

}

.fa.fa-facebook-official{
   color: #3b5998;
   /* background-color: white; */
   height: 14px;
   width: 15px;
   margin-right:20px;
   line-height: 15px;
   border-radius: 1%;



  
}

.fa.fa-instagram {
  color: #fff;
  height: 16px;
  border-radius:40%;
  line-height: 18px;
  margin-top: -10px;
  /* background: #f09433; */
  /* background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1); */
}

.fa.fa-linkedin-square{

  color: #0072b1;
  /* background-color: white */
  height: 30px;
  width: 30px;
  
  line-height: 15px;
  border-radius: 21%;
}


.fa.fa-twitter {
 color: #00acee;
 margin-left: 5px;
}
