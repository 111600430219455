.header {
  width: 100%;
  line-height: 80px;
  

 
}
@media screen and (max-width: 768px) {
  .header {
    margin-top: 0;
    height: 120px;

  }
}


@media screen and (max-width: 480px) {
  .header {
    margin-top: 0;
  height: 120px;

  }
}

@media screen and (max-width: 992px) {
  .header {
    margin-top: 0;
  height: 120px;

  }
}



.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  column-gap: 1rem;
  position: relative;
}

.nav__item {
  font-weight: 550;
  font-size: 1.1rem;
  color: #0e0e0e;
  font-family: Exo, sans-serif;
  text-decoration: none;
  transition: 0.3s;
  position: relative;
}




.active {
  color: purple;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.active::before {
  content: '';
  position: absolute;
  bottom: 25px; 
  left: 0;
  width: 100%;
  border-bottom: 2px solid purple; 
  transition: border-bottom 0.8s ease-in;
}

.active:hover::before {
  border-bottom: 2px solid transparent; 
  
}




.nav__right p {
  font-weight: 500;
  color: #0a2b1e;
  font-size: 1.1rem;
}

.nav__right p i {
  font-size: 1.3rem;
}

.mobile__menu span i {
  font-size: 1.4rem;
  color: #0a2b1e;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

@media only screen and (max-width: 991px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0a2b1ec2;
    z-index: 99;
    display: none;
  }

  .nav__list {
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    padding-top: 80px;
  }

  .mobile__menu {
    display: block;
    position: absolute;
    top: 100px; /* Adjust the top position based on your design */
    right: 0;
    /* transform: translateX(-500%); */
    
  }
  .nav__right {
    display: block;
    position: absolute;
    
    /* top:-10; */
    right: 0; 
    /* transform: translateX(20%); */

  }
  
  

  .active__menu {
    display: block;
  }
}
.logo{
  width: 10%;
  height: 20%;
  margin-top: 30px;
  margin-right: 0px;
  /* border-radius: 40px; */
  
  background-size: cover; 
}

.header1 {
  
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  
  
}
