.content-container{
    
    margin-bottom: 20px;
    text-align: center;
    /* margin: auto 25%; */
  
    
}

.post img{
    max-width: 80%;
    margin-top: 20px;
    align-content: center;
    border-radius: 50px;

}
.post{
    transition: all 400ms ease;
    margin-bottom: 50px;
    background:#E6E6FA;
    border-radius: 50px ;
   

    
    
    
    
}
.post:hover{
    transform: scale(1.04);


}

.light{
    position:relative;
    font-weight: bold;
}

.light::after{
    content: '';
    position: absolute;
    width: 20%;
    height: 4px;
    background-color:gold;
    border-radius: 50px;
    transform: translateX(-50%);
    left: 50%;
    bottom:-8px;
}