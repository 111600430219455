@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=ABeeZee&family=Oswald:wght@200..700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee&family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee&family=Oswald:wght@200..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee&family=Exo:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Salsa&display=swap');



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section,
footer {
  padding-top: 60px;
  padding-bottom: 1px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 20px;
  font-weight:bolder ;
  font-family: Oswald, sans-serif;
  color: #0a2b1e;
  font-weight: 600;
}

p {
  color:#0a2b1e;
  font-family: "Exo", sans-serif;
}

button.bton {
  background:purple;
  font-family: Exo, sans-serif;
  color: #f1f1f1;
  padding: 7px 25px;
  border-radius: 50px;
  border:transparent;
  

}

button.bton:hover {
  
  background-color:rgba(128, 0, 128, 0.8); 
}



i {
  color:#560bad;
}



.heading-pop{
  position:relative;
  font-weight: bold;
  margin-bottom: 20px;
}

.heading-pop::after{
  content: '';
  position: absolute;
  width: 150px;
  height: 3px;
  background-color:gold;
  border-radius: 50px;
  left: 0;
  bottom:-8px;
  
}

.bgclr{
  background-color: #fafafa;
}

.cor-head{
  position:relative;
  font-weight: bold;
  margin-bottom: 20px;

}

.cor-head::after{
  content: '';
  position: absolute;
  width: 150px;
  height: 3px;
  background-color:gold;
  border-radius: 50px;
  left: 45%;
  bottom:-8px;  

}




