.background1{
    background-color: #fafafa;
    border-radius:50px;

}

.blog-content{
    width: 70%;
    margin-bottom: 60px;
    font-family:serif;
    
}

.side-bar{
    width: 30%; 
    border: 1px solid;
    margin-left: 20px;
    height:fit-content;
    font-size:large;
    /* display: flow-root; */
    background-color:white;
    margin-bottom: 60px;
    padding: 20px;
    color: blueviolet;
    /* letter-spacing: .1vh; */
    

}
