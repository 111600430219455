.img-container{

   /* text-align:end; */
   
   margin: 0px;
   margin-bottom: 70px;
    
}

.img-container img {
    width: 90%;
    margin-top: 50px;
    border-radius: 30px;
  





}

.enroll a {
    text-decoration: none;
    font-weight: 600;
    font-size: 0.9rem;
    color:blue;
  }

  
  
  .enro {
    background-color: transparent;
    font-size: 15px;
    color:#8800b5;
    margin-left: 70px;
    margin-top: 15px;
    width: 30%;
    text-decoration: none;  
    padding: 1px 2px; 
    border:2px solid #8800b5; 
    border-radius: 8px; 
    cursor: pointer; 
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: color 0.5s; 
  }
  
  .enro:hover {
    color: white; 
  }
  
  
  .enro::before {
  
    content: "";
    position: absolute;
    top:100%;
    left: 100%;
    width: 120%;
    height: 220%;
    background-color: #8800b5;
    border-radius: 50%;
    z-index: -1;
    transition: all 0.4s ease;
  
  }
  
  .enro:hover::before{
  
    top: -20px;
    left:-10px;
  
  }



  .leftcol{

    line-height: 20px;

  }

