


.bg{

  border-radius: 50px;
  height: 350px;
  background-size: 100% 100%;

 
}
@media screen and (max-width: 768px) {
  .bg {
    height: 250px; /* Adjust the height for medium screens */
  }
}

/* For small screens */
@media screen and (max-width: 480px) {
  .bg {
    height: 250px; /* Adjust the height for small screens */
  }
}



button.btnn {
  background:orangered;
  /* background:; */
  font-family: Oswald, sans-serif;
  color: #fff;
  padding: 7px 25px;
  border-radius: 30px;
  border: solid;
  width: 150px;
}

button.btin {
  background:rgb(97, 18, 77);
  font-family: Oswald, sans-serif;
  color: #fff;
  padding: 7px 25px;
  border-radius: 30px;
  border: solid;
  width: 150px;
}




.ministry_img img {
  
  height: 60px;
  display: flex;
 border-radius: 30px;
 margin-bottom: 20px;
 /* margin-top: 20px; */
 /* justify-content: center; */
 /* align-items: center; */
  /* margin-left: 10px; */
  /* margin-top: 50px  ; */
  /* margin-top: 170px; */
  
}






