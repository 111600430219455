

.place{

    margin-top: -30px;
    /* background: linear-gradient( to right, #84d2ff, #8d5acd) */
    
}
.place-info{
    max-width: 600px;
  margin-left: 55px;
  font-family:'Times New Roman', Times, serif;
    
  
}

.text-wrapper {
  color: #000000;
  margin-top: 30px;
  font-family: "ABeeZee-Regular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  height: 24px;
  line-height: 34.8px;
  white-space: nowrap;
  text-align:center;


}


