@import url('https://fonts.googleapis.com/css2?family=Karla&display=swap');

.custom-font {
  font-style:normal;
  font-family:'Karla', sans-serif;
  font-size:18px;
  text-align:center;
  padding: 30px;
  margin-top: -20px;
  font-weight:lighter;
 
  line-height: 1;
 
 

}

.single__feature{

  border: 1px solid ;
  border-top-right-radius: 70px;
  padding: 50px;
  margin-bottom: 50px;
  
  padding: 50px;
  transition: all 400ms ease;
  box-sizing: border-box;
   overflow: hidden; 
 
  
}



.single__feature:hover{
  transform: scale(1.04);


}
.single__feature h2 i {
  font-size: 2.5rem;
}

.single__feature h6 {
  font-weight: 600;
  
}




