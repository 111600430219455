.bod6 {
    background-color:#fafafa;
    /* background-image: url(../../assests/images/aboutbg1.jpg); */
    background-position:center;
    /* margin-top: -55px; */
    margin-bottom: 20px;
    padding-bottom: 30px;
    border-radius: 50px 90px 50px 50px;
    
     
    
   }


.about__bg{
  /* background-image: url('../../assests/images/'); */
  background-color:#e0b8c5;



}

.bg__b{

  margin-top: 80px;
  /* margin-bottom: 50px; */
}


