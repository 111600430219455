.contact-form {

  padding: 60px;
  background-color:#ffffff;
  border-radius: 50px;
  
  -webkit-box-shadow:0px 0px 15px 5px rgba(112,18,122,0.1);
  -moz-box-shadow: 0px 0px 15px 5px rgba(112,18,122,0.1);
  box-shadow: 0px 0px 15px 5px rgba(112,18,122,0.1);
  
}

.form-group {
  margin-left: 20px;

  margin-bottom: 2px;
}
  

  
  .form-input{
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px; 
    border:none;
    border-bottom: 2px solid #333;
    padding: 2px 0;
  }
  
  .form-submit {
    background-color: #4c61af;
    color: white;
    margin-top: 20px;
    padding: 0px 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .form-submit:hover {
    background-color: rgb(71, 87, 181);
  }


  .form-textarea {
    width: 60%;
    padding: 8px;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: -11px;

  }


  
  