.bod7 {

    margin-top: 0px;
    margin-bottom: 20px;
    
    
    
    
   }

   .single__course__item {
    padding: 15px;
  }
  
  .course__img img {
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .enroll a {
    text-decoration: none;
    font-weight: 600;
    font-size: 0.9rem;
    color:blue;
  }

  
  
  .enroll {
    background-color: transparent;
    color:#8800b5;
    width: 156px;
    text-decoration: none;  
    padding: 5px 20px; 
    border:2px solid #8800b5; 
    border-radius: 8px; 
    cursor: pointer; 
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: color 0.5s; 
    margin-bottom: 30px;
  }
  
  .enroll:hover {
    color: white; 
  }
  
  
  .enroll::before {
  
    content: "";
    position: absolute;
    top:100%;
    left: 100%;
    width: 120%;
    height: 220%;
    background-color: #8800b5;
    border-radius: 50%;
    z-index: -1;
    transition: all 0.4s ease;
  
  }
  
  .enroll:hover::before{
  
    top: -20px;
    left:-10px;
  
  }
  

.image-container {
    background-image: url(../../assests/images/new11.png);
    background-size: cover;
    
    background-position: center;
    height: 100%; 
    width:100%;
    @media (max-width: 767px) {
      height: 200px; 
     
     
    }
    
  }


.custom-paragraph {
    color: rgb(0, 0, 0);
    font-size: 16px;
    font-style: normal;
    font-family:'Exo', sans-serif;
    text-align:left;
    
  }
  

  
  