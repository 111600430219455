.back-to-top-button {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #082671;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }
  
  .back-to-top-button.visible {
    display: block;
  }
  
  .back-to-top-button:hover {
    opacity: 0.8;
  }
  