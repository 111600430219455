.choose__img img {
  border-radius: 15px;
}

.choose__content {
  padding-right: 50px;
}

.choose__content h2 {
  font-weight: 600;
}

.choose__img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  z-index: 777;
}

.play__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 7777;
  width: 20px;
  height: 23px;
  border-radius: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play__icon i {
  color:red;
  font-size: 3rem;
  padding: 10px;  
  
}

.choose__img img {

  width: 100%;
  height: 100%;
 
}


.bw1{
  
  width: 100%;
  height: 350px;
  margin-top: 0px;
  margin-left: 0px;

}


.choose-us-section {
  background-image: url('../../assests/images/pattern1.png');
  /* background-size: cover;  */
  background-position: center; 
  padding-bottom: 40px;
  /* height: 440px; */

/*   
  @media (max-width: 767px) {
    height: 800px;  
  }
  @media (max-width: 480px) {
    height: 600px; 
  } */
}


