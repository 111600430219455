
.highlighted {
  font-size:medium;
  font-weight: 700; 
  color:rgb(77, 51, 144);
  
}

.fa-whatsapp {
  color:rgb(29, 210, 29); 
  font-size:25ypx;
}
.icon-link {
  text-decoration: none; 
}
.hv1{

  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
   color:black; 
   opacity: 100%;
   font-weight: 700;

}
