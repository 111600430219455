.framerr {
  text-align:center;
  /* height: 350px; */
  /* position: relative; */
  margin-top: 50px; 
  text-align:center;
}


 .text-wrapper {
  color: #424251;
  
  font-family: "ABeeZee-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 24px;
  line-height: 34.8px;
  position:relative;
  top: 4px;
  white-space: nowrap;

}



.read-more {
  color: #0077ff;
  font-family: Oswald, sans-serif;
  font-size: 20px;
  font-weight: 600;

  letter-spacing: 0.2px;
  line-height: 28px;

  text-decoration:none;
}

.about__title{
  color: #131325;
  font-family: Oswald, sans-serif;
  font-size: 200%;
  font-weight: 700;
  letter-spacing: -0.72px;
  /* line-height: 62.4px; */
  position: relative;
}

@media only screen and (max-width: 767px) {
  .about__title {
      font-size: 132%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__title {
      font-size: 150%;
  }
}

.about__desc{

  
    color: #424251;
    font-family: 'ABeeZee-Regular, Helvetica';
    font-size: 20px;
    font-weight: 400;
    /* left: 19px; */
    letter-spacing: 0;
    /* line-height: 34.8px; */
}

@media only screen and (max-width: 767px) {
  .about__desc {
      font-size: 15px;
    /* line-height: 0px; */

  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about__desc {
      font-size: 15px;
  }
}




