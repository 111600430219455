

.pay-now-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #3498db;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pay-now-button:hover {
  background-color: #134c72;
  color: rgb(255, 255, 255);
}


.bgclr{


  border-radius: 60px;
}


  
.style22{
 
  position:relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 7777;
  width: 20px;
  height: 23px;
  border-radius: 60%;
  display: flex;
  align-items: center;
  justify-content: center;  
  cursor: pointer;
  margin-top:15px;
}

.style22 i{
  color:red;
  font-size: 2.8rem;
 
}

.contact-main-bg{

  
  border-radius: 50px;
}

.yotubeboxstyle{

  
  background: linear-gradient(0deg, hsla(197, 14%, 57%, 1) 0%, hsla(192, 17%, 94%, 1) 100%);
  padding: 10px; 
  max-width: 700px; 
  border-radius: 50px;
  height: 90px;
  width: 400px;
  margin-top: 20px;
  margin-bottom: 30px;
  -webkit-box-shadow:0px 0px 9px 6px rgba(0,0,0,0.05);
-moz-box-shadow: 0px 0px 9px 6px rgba(0,0,0,0.05);
box-shadow: 0px 0px 9px 6px rgba(0,0,0,0.05);


}




   
  
  
  
  