.div-framer {
    background-color: #fafafa;
    border-radius: 50px 90px 50px 50px; 
    /* height: 500px; */


    
  }

  button.btnn {
    background:orangered;
    /* background:; */
    color: #fff;
    padding: 7px 25px;
    border-radius: 30px;
    border: solid;
    width: 150px;
  }
  
  button.btin {
    background:rgb(97, 18, 77);
    
    color: #fff;
    padding: 7px 25px;
    border-radius: 30px;
    border: solid;
    width: 150px;
  }

  .heading-p{
    position:relative;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .heading-p::after{
    content: '';
    position: absolute;
    width: 150px;
    height: 3px;
    background-color:gold;
    border-radius: 50px;
    left: 45%;  
    transform: translateX(-10px);
    bottom:-8px;
   
    

    
  }
  @media screen and (max-width: 600px) {
    .heading-p::after {
        left: 40%; 
        transform: translateX(-20px);
  

        

    }
  }

 


  .taet__data{
    width: 400px;
    padding: 30px;
    background-color: #fafafa;
    font-family: 'Oswald, sans-serif';
    justify-content: center;
    border: 2px solid #ddd;
    border-radius: 20px;
    margin: 10px 20px 0px 0px;
   
  }


 