.single__course__item {
    padding: 15px;
    max-width: 500px;
  }
  
  .course__img img {
    border-radius: 50px;
    margin-bottom: 20px;
  }
  
  .enroll a {
    text-decoration: none;
    font-weight: 600;
    font-size: 0.9rem;
    color:blue;
  }
  
  .lesson,
  .students,
  .rating {
    font-size: 0.9rem;
    color: #0a2b1e;
    font-weight: 500;
  }
  
  .lesson i,
  .students i,
  .rating i {
    font-weight: 400;
  }
  
  .course__title {
    line-height: 2rem;
  }
  
  .course__top__left h2 {
    font-weight: 600;
  }
  
  
  .enroll1 {
    background-color: transparent;
    color:#8800b5;
    padding: 5px 20px; 
    border:2px solid #8800b5; 
    border-radius: 8px; 
    cursor: pointer; 
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: color 0.5s; 
  } 
  
  .enroll1:hover {
    color: white; 
  }
  
  
  .enroll1::after {
  
    content: "";
    position: absolute;
    top:100%;
    left: 100%;
    width: 120%;
    height: 220%;
    background-color: #8800b5;
    border-radius: 50%;
    z-index: -1;
    transition: all 0.4s ease;
  
  }
  
  .enroll1:hover::before{
  
    top: -20px;
    left:-10px;
  
  }




.topbg{

  background-color:aliceblue;
  height: 500px;
  margin-top:-59px;
}
 

.wrapper{

  margin-left: 0px;
}





  
  