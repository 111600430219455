.single__course__item {
  padding: 15px;
}

.course__imgg img {
  border-radius: 50px;
  margin-bottom: 20px;
}

.enroll a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  color:blue;
}



.course__title {
  line-height: 2rem;

}

.course__top__left h2 {
  font-weight: 600;
}


.enroll1 {
  background-color: transparent;
  color:#8800b5;
  padding: 5px 20px;
  font-family: ABeeZee-Regular, Helvetica;
  border:2px solid #8800b5; 
  border-radius: 8px; 
  cursor: pointer; 
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: color 0.5s; 
} 

.enroll1:hover {
  color: white; 
}


.enroll1::before {

  content: "";
  position: absolute;
  top:100%;
  left: 100%;
  width: 120%;
  height: 220%;
  background-color: #8800b5;
  border-radius: 50%;
  z-index: -1;
  transition: all 0.4s ease;

}

.enroll1:hover::before{

  top: -20px;
  left:-10px;

}
.heading-pop{
  position:relative;
  font-weight: bold;
  margin-bottom: 20px;
}

.heading-pop::after{
  content: '';
  position: absolute;
  width: 150px;
  height: 3px;
  background-color:gold;
  border-radius: 50px;
  /* transform: translateX(-50%); */
  left: 0;
  bottom:-8px;
  
}


